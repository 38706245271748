import React from 'react'
const Footer = ({props}) => (
	<footer role="footer" className="footer text-center">
		<div className="container">
			<section className="col-xs-12 col-sm-6 col-md-3">
				<h2 className="footer_heading">ZETGOO</h2>
				<p className="footer_list_item">We are a research team that focus on Cognitive Science to apply for Smart Applications.</p>
			</section>
			<section className="col-xs-12 col-sm-6 col-md-3">
				<h2 className="footer_heading">Useful Links</h2>
				<ul className="nobull">
					<li className="footer_list_item"><a href="http://skeleton.zetgoo.com" target="_blank">Skeleton</a></li>
					<li className="footer_list_item"><a href="http://vision.zetgoo.com" target="_blank">Vision</a></li>
					<li className="footer_list_item"><a href="http://ling.zetgoo.com" target="_blank">Linguistics</a></li>
					<li className="footer_list_item"><a href="http://things.zetgoo.com" target="_blank">IoT</a></li>
					<li className="footer_list_item"><a href="http://thinktree.zetgoo.com" target="_blank">ThinkTree</a></li>
				</ul>
			</section>
			<section className="col-xs-12 col-sm-6 col-md-3">
				<h2 className="footer_heading">Navigational</h2>
				<ul className="nobull">
					<li className="footer_list_item"><a href="http://zetgoo.com">Home</a></li>
					<li className="footer_list_item"><a href="#section-five">Contact</a></li>
					<li className="footer_list_item"><a href="#section-six">Join Us</a></li>
				</ul>
			</section>
			<section className="col-xs-12 col-sm-6 col-md-3">
				<h2 className="footer_heading">Office</h2>
				<ul className="nobull">
					<li className="footer_list_item"><span className="fa fa-map-marker"></span><span style={{marginLeft: '12px'}}>Mizuki Park, Nguyen Van Linh Street, Ho Chi Minh City, Vietnam</span></li>
					<li className="footer_list_item"><span className="fa fa-phone"></span><span style={{marginLeft: '12px'}}>+84 982 43 71 72</span></li>
					<li className="footer_list_item"><span className="fa fa-envelope"></span><span style={{marginLeft: '12px'}}>contact.zetgoo@gmail.com</span></li>
				</ul>
			</section>
		</div>
		<br/>
		<div className="container">
			<section role="socil-icons" className="socil-icons">
				<section role="socil-icons" className="socil-icons">
					<a href="https://twitter.com/Zetgoo1"><i className="fa fa-twitter" aria-hidden="true"></i></a>
					<a href="https://www.facebook.com/zetgo0"><i className="fa fa-facebook" aria-hidden="true"></i></a>
					<a href="https://www.linkedin.com/in/zet-goo-787511139?trk=nav_responsive_tab_profile"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
					<a href="https://plus.google.com/u/0/108481921083651717425"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
					<a href="https://www.youtube.com/channel/UC5qSL8hX9R9UgMeClk8HfUQ"><i className="fa fa-youtube" aria-hidden="true"></i></a>
				</section>
			</section>
			<nav role="footer-nav">
				<a href="#">Terms of Use </a>
				<a href="#">Privacy Policy</a>
			</nav>
			<p className="copy">© 2015 Zetgoo. All rights reserved. Made with <i className="fa fa-heart pulse"></i> by <a href="#">Designstub</a></p>
		</div>
	</footer>
)
export default Footer