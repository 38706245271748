import React from 'react'
const Main = ({props}) => (
	<main role="main" id="main-wrapper">
        <section role="loges" className="top-pan">
        	<div className="container">
            	<ul className="row">
                	<li className="col-xs-12 col-sm-3 col-md-2 col-lg-2 ">Our platforms</li>
                	<li className="col-xs-4 col-sm-3 col-md-2 col-lg-2">
                    	<a href="http://skeleton.zetgoo.com" target="_blank"><img src="images/logoes/logoes-5.png" alt="" className="img-responsive"/></a>
                    </li>
                    <li className="col-xs-4 col-sm-3 col-md-2 col-lg-2 ">
                    	<a href="http://vision.zetgoo.com" target="_blank"><img src="images/logoes/logoes-1.png" alt="" className="img-responsive"/></a>
                    </li>
                    <li className="col-xs-4 col-sm-3 col-md-2 col-lg-2">
                    	<a href="http://ling.zetgoo.com" target="_blank"><img src="images/logoes/logoes-2.png" alt="" className="img-responsive"/></a>
                    </li>
                   
                    <li className="col-xs-4 col-sm-3 col-md-2 col-lg-2">
                    	<a href="http://things.zetgoo.com" target="_blank"><img src="images/logoes/logoes-4.png" alt="" className="img-responsive"/></a>
                    </li>
                     <li className="col-xs-4 col-sm-3 col-md-2 col-lg-2">
                    	<a href="http://thinktree.zetgoo.com" target="_blank"><img src="images/logoes/logoes-3.png" alt="" className="img-responsive"/></a>
                    </li>
                </ul>
            </div>
        </section>
        <section className="section-one text-center" id="section-one">
        	<div className="container">
            	<header role="title-page">
                    <h4>What we do</h4>
                    <h2>We research and conduct platforms<br/>toward the new age of cognitive applications.</h2>
                </header>
                <article>
                	<p>Our team focus on researching & developing the cutting-edge technologies based on <a href="https://en.wikipedia.org/wiki/Cognitive_science" target="_blank">Cognitive Science</a> for building the intellectual applications. We develop a Self-Taught Learning ecosystem that consists of five unique platforms such as 
                	<a href="http://skeleton.zetgoo.com" target="_blank"> skeleton</a>, <a href="http://vision.zetgoo.com" target="_blank"> vision</a>, <a href="http://link.zetgoo.com" target="_blank">linguistics</a>, 
                	<a href="http://iot.zetgoo.com" target="_blank"> IoT</a>, 
                	and <a href="http://thinktree.zetgoo.com" target="_blank">think tree </a> platforms. These platforms are researched & developed
                      based on the-state-of-the-art researches in <a href="https://en.wikipedia.org/wiki/Artificial_intelligence" target="_blank">AI</a>, <a href="https://en.wikipedia.org/wiki/Big_data" target="_blank">Big Data</a>, and <a href="https://en.wikipedia.org/wiki/Blockchain">Blochchain</a>. We provide these platforms' features as SDK & API so that you can develop your smart apps in simplified way.
                    </p>
                </article>
                	<div className="row four-box-pan" role="four-box">
                        <section className="col-xs-12 col-sm-6 col-md-3">
                        	<figure><i className="fa fa-rocket" aria-hidden="true"></i></figure>
                        	<h5>STARTUP FEATURE</h5>
                        	<p>
                                <span className='vision'>ZETGOO </span>
                                  team believes that the more cognitive applications are developed, the more convenient our life is.
                            </p>                        
                        </section>
                        <section className="col-xs-12 col-sm-6 col-md-3">
                        	<figure><i className="fa fa-magic" aria-hidden="true"></i></figure>
                        	<h5>Higly customizable</h5>
                            <p>Our platforms provide the functionalities both Rest API and SDK for developing your applications.</p>
                        </section>
                        <section className="col-xs-12 col-sm-6 col-md-3">
                        	<figure><i className="fa fa-paper-plane" aria-hidden="true"></i></figure>
                        	<h5>Simplified workflow</h5>
                            <p>We provide the full documents & code samples so that you are easy for integrating with our platforms.</p>
                        </section>
                        <section className="col-xs-12 col-sm-6 col-md-3">
                        	<figure><i className="fa fa-television" aria-hidden="true"></i></figure>
                        	<h5>Smart Applications</h5>
                            <p>We provide cognitive services based on understanding user's behaviours for developing the smart applications.</p>
                        </section>
                    </div>
            </div>
        </section>
        <section className="section-two" id="section-two">
            <section>
            	<div className="col-xs-12 col-sm-6 col-md-6">
                	<article>
                        	<h2>Get Maxium benifites by working with our experts.</h2>
                            <p>We are a passionate team about building the world class product. Our members come mostly from startup companies with highest qualification.
                              <span class = 'vision'> ZETGOO </span>
                              always focus on researching and developing the products with high knowledge that uses AI, big data and cloud technologies. We also are available to support for startup teams or companies building their products along with us.</p>
                            <ul>
                                <li>Deep understanding in academic and industrial researches. </li>

                                <li>Have many years of experiences in startup companies about big data, AI, image processing, computer vision and fin-tech.  </li>

                                <li>Always working with qualification and commitment.</li>

                                <li>Support 24/7 for customers.</li>

                            </ul>

                        </article>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6">
                    <figure className="row" style={{backgroundImage: "url(images/images-1.jpg)"}}></figure>
                </div>
             </section>
            <div className="clearfix"></div>
             <section>
                <div className="col-xs-12 col-sm-6 col-md-6">
                	<figure className="row" style={{backgroundImage:"url(images/images-2.jpg)"}}></figure>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6">
                	<article>
                    		<h2>We even give you more than your expectation</h2>

                            <p>We will provide our researches and platforms in serverless services for startup teams or companies in building their products in
                              <span class = 'vision'> FREE </span>
                              charge. It is important that we are highly available in researching and supporting for applying big data, AI, image processing, computer vision and cloud technologies into product development.</p>

                            <p>
                              <span class = 'vision'> ZETGOO </span>
                               team also has much experiences in building startup products. Specify, we have supported for
                              <a class = 'zgLink' target = '_blank'
                                href = 'https://www.youtube.com/watch?v=q5YlMsbKKcQ'> SURFUL </a> team that obtains
                                <span class = 'vision'> 1st </span>
                                prize in
                              <a class = 'zgLink' target = '_blank'
                                href = 'http://thanhnien.vn/gioi-tre/ho-bien-mat-ban-thanh-san-bong-878896.html'> STARUPWHEEL 2017 </a>.
                            </p>

                        </article>
                </div>
             </section>
            <div className="clearfix"></div>
            <div class="video-pan text-center">

                	<a id="video" class="player" data-property="{videoURL:'https://youtu.be/LdSTVYbgxpg',containment:'.bg-container-youtube', showControls:false,autoPlay:true,mute:true,loop:true, startAt:0, opacity:1, addRaster:false, quality:'large'}"></a>


                    <div class="bg-container-youtube"></div>

                	<header>
                    <a class="popup-vimeo video-button" href="https://vimeo.com/235537229">
                      <i class="fa fa-caret-right" aria-hidden="true"></i>
                    </a>

                    <h4>Video Preview Of Product</h4>

                    </header>

                </div>
            {
            	/*<div className="slider-pan">
            	<header role="title-page" className="text-center">
                	<h4>Customer Voice</h4>
                </header>
                
            </div>*/
            }

        </section>
        <section role="loges" className="top-pan" id="section-partners">
            <div className="container">
                <ul className="row">
                    <li className="col-xs-12 col-sm-3 col-md-2 col-lg-2 ">Our Partners</li>
                    <li className="col-xs-4 col-sm-3 col-md-2 col-lg-2">
                        <a href="http://topreal.vn"  target="_blank"><img src="images/logoes/logo_topreal.png" alt="" className="img-responsive"/></a>
                    </li>
                    <li className="col-xs-4 col-sm-3 col-md-2 col-lg-2 ">
                        <a href="https://startupwheel.vn/service/surful" target="_blank"><img src="images/logoes/logo_surful.png" alt="" className="img-responsive"/></a>
                    </li>
                </ul>
            </div>
        </section>
        {/*
        	<section className="section-three" id="section-three">

            	<div className="container">

                	<header role="title-page" className="text-center">

                    	<h4>Flexible Plans</h4>

                        <h2>Select the plan that suits you. Upgrade,<br/> downgrade, or cancel anytime.</h2>

                    </header>

                    <div className="pricing">

                    	<section>

                        	<header><h4>Bronze</h4></header>

                            <h2><span>$10</span> /mo</h2>

                            <ul>

                                <li>Free Shipping</li>

                                <li>24/7 Support</li>

                                <li>Single Licence</li>

                            </ul>

                            <input name="" type="button" value="get Strated"/>

                        </section>

                        <section>

                        	<header><h4>Silver</h4></header>

                            <h2><span>$20</span> /mo</h2>

                            <ul>

                                <li>Free Shipping</li>

                                <li>24/7 Support</li>

                                <li>Single Licence</li>

                            </ul>

                            <input name="" type="button" value="get Strated"/>

                        </section>

                        <section>

                        	<header><h4>Gold</h4></header>

                            <h2><span>$50</span> /mo</h2>

                            <ul>

                                <li>Free Shipping</li>

                                <li>24/7 Support</li>

                                <li>Single Licence</li>

                            </ul>

                            <input name="" type="button" value="get Strated"/>

                        </section>

                    </div>


                </div>

            </section>
        */}
           {/*
            <section className="section-four" id="section-four">
            	<div className="container">
                    <header role="title-page" className="text-center">

                        <h4>Our platforms</h4>

                        <h2>Five unique platforms for <br/>the intellectual applications.</h2>

                    </header>
                    <div className="team-pan row">
                        <section className="col-xs-12 col-sm-6 col-md-3">
                        	<figure><img src="images/logoes/logoes-1.png" alt="" className=" img-responsive"/></figure>
                        	<header>
                            	<h5>Antony Casalena</h5>
								<h6>Vice president</h6>
                                <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-dribbble" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-behance" aria-hidden="true"></i></a>
                            </header>
                        </section>
                        <section className="col-xs-12 col-sm-6 col-md-3">
                        	<figure><img src="images/team/image-2.jpg" alt="" className=" img-responsive"/></figure>
                        	<header>
                            	<h5>Anaia Doe</h5>
								<h6>Creative Head</h6>
                                <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-dribbble" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-behance" aria-hidden="true"></i></a>
                            </header>
                        </section>
                        <section className="col-xs-12 col-sm-6 col-md-3">
                        	<figure><img src="images/team/image-3.jpg" alt="" className=" img-responsive"/></figure>
                        	<header>
                            	<h5>Johnathan Doe</h5>
								<h6>Development Lead</h6>
                                <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-dribbble" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-behance" aria-hidden="true"></i></a>
                            </header>
                        </section>
                        <section className="col-xs-12 col-sm-6 col-md-3">
                        	<figure><img src="images/team/image-4.jpg" alt="" className=" img-responsive"/></figure>
                        	<header>
                            	<h5>Julian Gulia</h5>
								<h6>Marketing Head</h6>
                                <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-dribbble" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-behance" aria-hidden="true"></i></a>
                            </header>
                        </section>
                    </div>
                </div>
            </section>
            */}
            <section className="section-five" id="section-five">
            	<div className="container">
                	 <header role="title-page" className="text-center">
                        <h4>Newsletter Subscribe</h4>
                        <h2>Subscribe to get monthly products updates<br/>and exclusive offers</h2>
                    </header>
                    <div className="subscribe-form">
                        <div className="ntify_form">
                            <form name="subscribeform" id="subscribeform">
                                <input name="email" type="email" id="subemail" placeholder="Email Address"/>
                                <button type="button" name="" value="Submit">
                                Subscribe <i className="fa fa-envelope" aria-hidden="true"></i></button>
                            </form>
                            <div id="mesaj"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-six" id="section-six">
            	<div className="container">
                	 <header role="title-page" className="text-center">
                        <h4>Get in touch</h4>
                        <h2>Have any questions? Our team will happy to<br/>answer your questionss.</h2>
                    </header>
                    <div className="contact-form">
                        <div id="message"></div>
                            <form name="cform" id="cform">

                                <div className="col-md-6 col-lg-6 col-sm-6">

                                	<input name="name" id="name" type="text" placeholder="Full Name"/>

                                </div>

                                <div className="col-md-6 col-lg-6 col-sm-6">

                                	<input name="email" id="email" type="email" placeholder="Email Address"/>

                                </div>

                                <div className="clearfix"></div>

                                <textarea name="comments" id="comments" cols="" rows="" placeholder="Question in Detail"></textarea>

                                <div className="clearfix"></div>
                                <input name="" type="button" value="Send mail"/>

                                <div id="simple-msg"></div>

                        	</form>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <div className="map-wrapper">
                	<div id="surabaya"></div>
                </div>

            </section>
        </main>
)
export default Main


/*
<div class="mbYTP_wrapper" id="wrapper_mbYTP_video" style={{position: 'absolute', zIndex: '0', minWidth: '100%', minHeight: '100%', left: '0px', top: '0px', overflow: 'hidden', opacity: '1', transitionProperty: 'opacity', transitionDuration: '2000ms'}}>
                		<iframe id="mbYTP_video" class="playerBox" style={{position: 'absolute', zIndex: '0', width: '1843.44px', height: '891px', top: '0px', left: '0px', overflow: 'hidden', opacity: '1', marginTop: '-181.5px', marginLeft: '-218.72px'}} frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="640" height="360" src="https://www.youtube.com/embed/0K-g84sK6R0?autoplay=0&amp;modestbranding=1&amp;controls=0&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&amp;version=3&amp;playerapiid=mbYTP_video&amp;origin=http%3A%2F%2Fdesignstub.com&amp;allowfullscreen=true&amp;wmode=transparent&amp;iv_load_policy=3&amp;html5=1&amp;widgetid=1">
                		</iframe>
                		<div class="YTPOverlay" style={{position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%'}}>
                		</div>
                	</div>
                	
                    {{<video class="popup-vimeo video-button" controls>
                      <i class="fa fa-caret-right" aria-hidden="true"></i>
                      <source src="images/Zetgootheme.flv" type="video/ogg">
                      Your browser does not support the video tag.
                    </video> }}


                     <section className="section-four" id="section-four">
            	<div className="container">
                    <header role="title-page" className="text-center">

                        <h4>Meet The Team</h4>

                        <h2>Our team is passionate about bringing the <br/>best for our customers.</h2>

                    </header>
                    <div className="team-pan row">
                        <section className="col-xs-12 col-sm-6 col-md-3">
                        	<figure><img src="images/team/image-1.jpg" alt="" className=" img-responsive"/></figure>
                        	<header>
                            	<h5>Antony Casalena</h5>
								<h6>Vice president</h6>
                                <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-dribbble" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-behance" aria-hidden="true"></i></a>
                            </header>
                        </section>
                        <section className="col-xs-12 col-sm-6 col-md-3">
                        	<figure><img src="images/team/image-2.jpg" alt="" className=" img-responsive"/></figure>
                        	<header>
                            	<h5>Anaia Doe</h5>
								<h6>Creative Head</h6>
                                <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-dribbble" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-behance" aria-hidden="true"></i></a>
                            </header>
                        </section>
                        <section className="col-xs-12 col-sm-6 col-md-3">
                        	<figure><img src="images/team/image-3.jpg" alt="" className=" img-responsive"/></figure>
                        	<header>
                            	<h5>Johnathan Doe</h5>
								<h6>Development Lead</h6>
                                <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-dribbble" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-behance" aria-hidden="true"></i></a>
                            </header>
                        </section>
                        <section className="col-xs-12 col-sm-6 col-md-3">
                        	<figure><img src="images/team/image-4.jpg" alt="" className=" img-responsive"/></figure>
                        	<header>
                            	<h5>Julian Gulia</h5>
								<h6>Marketing Head</h6>
                                <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-dribbble" aria-hidden="true"></i></a>
                                <a href="#"><i className="fa fa-behance" aria-hidden="true"></i></a>
                            </header>
                        </section>
                    </div>
                </div>
            </section>
*/