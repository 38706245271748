import React from 'react'
const Header = ({props}) => (
	<header role="header" className="header-top" id="headere-top">
		<div className="header-fixed-wrapper" role="header-fixed">
			<div className="container">
				<hgroup className="row">
					<div className="col-xs-5 col-sm-2 col-md-2 col-lg-2">
						<h1>
							<a href="http://zetgoo.com" title="Rooky"><img src="images/logo.png" alt="Rooky" title="Rooky" className="logo"/></a>
						</h1>
					</div>
					<nav role="navigation" className="col-xs-12 col-sm-10 col-md-10 col-lg-10 navbar navbar-default affix-top">
						<div className="navbar-header">
							<button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
								<span className="sr-only">Toggle navigation</span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>
						</div>
						<div id="navbar" className="navbar-collapse collapse">
							<ul className="nav navbar-nav">
								<li className="active"><a href="#headere-top" title="Home">Home</a></li>
								<li><a href="#section-one" title="Overview">Overview</a></li>
								<li><a href="#section-two" title="Features">Features</a></li>
								<li><a href="#section-partners" title="Features">Partners</a></li>
								
								{/*<li><a href="#section-three" title="Pricing">Pricing</a></li>*/}
								
								{/*<li><a href="#section-four" title="Team">Team</a></li>*/}
								<li><a href="#section-five" title="Contact">Contact</a></li>
								<li><a href="#section-six" title="Join Us">Join Us</a></li>
							</ul>
						</div>
					</nav>
				</hgroup>
			</div>
		</div>
		<section className="text-center">
			<h2>Zetgoo. Yes, do it!</h2>
            <p>Let our intellectual and effort boost your life.</p>
            <a className="popup-vimeo video-button" href="https://vimeo.com/235537229"><i className="fa fa-caret-right" aria-hidden="true"></i></a>
			<a href="http://account.zetgoo.com" className="button-header">Get Strated</a>
		</section>
		<figure>
			<div className="parallax-window item tp-banner-container" data-parallax="scroll" data-image-src="images/banner-image.jpg" style={{height: '745px'}}></div>
		</figure>
	</header>
)
export default Header